.summary-business-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background-color: #f0f0f0;
}

.summary-business-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  padding: 30px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

.summary-business-textarea {
  width: 100%;
  height: 250px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 18px;
  font-family: 'Arial', sans-serif;
  box-sizing: border-box;
  resize: vertical;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.summary-business-textarea:focus {
  outline: none;
  border-color: #3f51b5;
  box-shadow: 0 0 10px rgba(63, 81, 181, 0.5);
}

.summary-business-textarea::placeholder {
  color: #ccc;
  font-style: italic;
}

.data-card, .flashcard-card {
  background-color: #ffffff;
  margin-top: 20px;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.data-section-title, .pyq-title, .summary-notes-title, .flashcard-title {
  margin-top: 16px;
  margin-bottom: 12px;
  font-size: 1.8em;
  color: #333;
}

.data-content, .summary-notes-content {
  margin-bottom: 12px;
  color: #555;
}

.data-item {
  margin-bottom: 16px;
}

.summary-business-button {
  width: 200px;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 8px;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  margin-top: 20px;
  background-color: #3f51b5;
  color: #ffffff;
  border: none;
}

.summary-business-button:hover {
  background-color: #2c3e50;
  box-shadow: 0 4px 10px rgba(63, 81, 181, 0.3);
}

.login-prompt-container, .on-screen-menu-container {
  position: fixed;
  z-index: 1000;
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.pyq-section {
  margin-top: 20px;
}

.pyq-card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.pyq-question {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.pyq-options {
  color: #555;
  margin-bottom: 5px;
}

.pyq-correct-answer {
  color: #4caf50; 
  font-weight: bold;
}

.summary-notes {
  margin-top: 20px;
}

.summary-notes-card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.summary-notes-title {
  font-size: 2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.summary-notes-content {
  color: #555;
  line-height: 1.6;
}

.flashcard-section {
  margin-top: 20px;
}

.flashcard-card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.flashcard-title {
  font-size: 2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.flashcard-question {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.knowledge-pointers {
  list-style-type: disc;
  padding-left: 20px;
  color: #555;
}

.knowledge-pointers li {
  margin-bottom: 8px;
  font-size: 1em;
}

.flashcard-container {
  perspective: 1000px;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 700px;
  height: 500px;
}
.card-number {
  color: #000; 
  font-size: 16px;
  align-items: center; 
  text-align: center;
}

.flashcard {
  width: 660px;
  height: 350px;
  position: relative;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  cursor: pointer;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.flashcard .front,
.flashcard .back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 10px; 
}

.flashcard .front {
  background-color: #bee1f4;
  color: #1d2b60;
}

.flashcard .back {
  background-color: #bee1f4;
  color: #1d2b60;
  transform: rotateY(180deg);
}

.flipped {
  transform: rotateY(180deg);
}  

.textarea-container {
  position: relative;
}

.textarea-label {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #555; 
}

.textarea {
  width: 100%;
  height: 200px; 
  padding: 15px;
  border: 1px solid #ccc; 
  border-radius: 10px;
  font-size: 16px;
  resize: vertical; 
  background-color: #f8f8f8; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
  font-family: 'Arial', sans-serif; 
}

.textarea:focus {
  outline: none;
  border-color: #007bff; 
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
}


  .navigation-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  
  .navigation-buttons button {
    margin: 0 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .navigation-buttons button:hover {
    background-color: #0056b3;
  }

  .container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }
  
  .nav {
    width: 350px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
  .nav button {
    border: none;
    background: #111122;
    color: white;
    font-size: 0.9em;
    padding: 0.8em 1.2em;
    border-radius: 20px;
    margin: 1em 5px;
    opacity: 0.85;
    transition: all 0.25s;
    cursor: pointer;
  }
  
  .nav button:hover {
    opacity: 1;
    transform: translateY(-2px);
  }
  
  .nav button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    transform: translateY(0px);
  }
  
  .nav button:focus {
    outline: none;
  }
  
  .cardNumber {
    color: white;
    display: flex;
    justify-content: center;
  }

  .card {
    margin: 10px auto;
    background: linear-gradient(#c75600, #f88d35);
    height: 350px;
    width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
   
    color: #ffffff;
    font-size: 24px;
    font-weight: 400;
    border-radius: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1),
      0px 8px 16px rgba(0, 0, 0, 0.1), 0px 16px 32px rgba(0, 0, 0, 0.1);
    position: relative;
    cursor: pointer;
  
    transition: 250ms;
    transform-style: preserve-3d;
    transform: perspective(1000px) rotateY(var(--rotate-y, 0))
      translateY(var(--translate-y, 0));
  }
  
  
  .side {
    background: linear-gradient(#96153e, #e02341);
    transform: rotateY(-180deg);
  }
  
 
  .card .front,
  .card .back {
    position: absolute;
    padding: 1rem;
    backface-visibility: hidden;
  }
  
  .card .front {
    transform: rotateY(0deg);
  }
  
  .card.side .front {
    opacity: 0;
    display: none;
  }
  
  .card .back {
    transform: rotateY(180deg);
  }
  
  .card small {
    opacity: 0.7;
    font-size: 0.7em;
    position: absolute;
    top: 3rem;
  }
  
  .card.side small {
    transform: rotateY(180deg);
  }
  
  .card small span {
    display: block;
    text-align: center;
    font-size: small;
  }
  
  .loading {
    color: white;
    width: 350px;
    text-align: center;
    margin: 20px auto;
  }

  form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
    height: 300px;
    width: 500px;
    background-color: #f0f0f0;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px;
  }
  
  .uploaded-row{
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px; 
    padding: 15px 20px;
    border-radius: 5px;
    background-color: #e9f0ff;
  }
  
  .upload-content{
    display: flex;
    align-items: center;
  }
.btn-text-pdf
{
  border-width: 2px;
  border-color: #111122;
  border-style: solid;
  width: fit-content;
  height: 60px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.flas-para-element{
  text-align: center;
  margin-bottom: 20px; 
}

.qs-ans{
  width: 100vh;
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
}

.form-control{
  background-size: cover;
  width: 500px;
  background: transparent;
  border: none;
}

.f-submit-button{
  margin: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.text-content-head{
  font-weight: bold;
  color: rgb(0, 3, 2);
}


.FlashcardGenerator_form__6p41K textarea {
  width: calc(100% - 35px);
  height: 382px;
}
.FlashcardGenerator_form__6p41K input[type=email], .FlashcardGenerator_form__6p41K input[type=password], .FlashcardGenerator_form__6p41K input[type=text], .FlashcardGenerator_form__6p41K textarea {
  border-radius: 15px;
  background-color: #eff2fe;
  border: 0;
  padding: 18px;
  color: #1d2b60;
  font-size: 15px;
  font-family: DM Sans,sans-serif;
  outline: 0;
  margin-top: 10px;
  transition: background-color .4s;
  resize: none;
}

.f-pdf-upload{
    color: #667197;
    width: calc(100% - 5px);
    height: 410px;
    border: 4px dashed #ccd4f3;
    background: #eff2fe;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
    flex-direction: column;
    

}

.f-text-upload{
  border-radius: 15px;
  background-color: #eff2fe;
  border: 0;
  padding: 18px;
  color: #1d2b60;
  font-size: 15px;
  font-family: DM Sans,sans-serif;
  outline: 0;
  margin-top: 10px;
  transition: background-color .4s;
  resize: none;
}

.f-answer-list-item{
  list-style-type: disc;
}

.flashcard-front{
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
}
.flashcard-back{
  display: flex;
  /* justify-content: space-around; */
  flex-direction: column;
}


.f-head-element {
  /* margin: 0px 0px 24px; */
  margin-top: 18px;
  font-weight: 300;
  line-height: 1.167;
  letter-spacing: -0.01562em;
  color: rgb(45, 222, 152);
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.f-para-element {
  /* margin: 0px 0px 10px; */
  /* margin-top: 18px; */
  /* font-weight: 300; */
  /* line-height: 1.167; */
  letter-spacing: -0.01562em;
  color: rgba(0, 0, 0, 0.87);
  /* font-size: 2rem; */
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.f-features-list {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.f-features-list li{
line-height: 23px;
font-size: 18px;
margin-bottom: 12px;
}
.f-tick-icon{
  color:green;
  margin-right: 3px;
}

.f-card-heading{
  font-size: 18px;
}
.f-error-message{
  color:red;
}

.copy-button-container {
  display: flex;
  justify-content: flex-end;
}
.copy-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.copied-message {
  font-size: 12px;
  color: green;
}


@media only screen and (max-width: 768px){
.f-head-element {
    font-size: 35px;
    margin-top: 8px;
    text-align: center;
}
.container{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.qs-ans{
  width: 300px;
}
.flashcard-container{
  width: 400px;
  padding: 0;
}
.flashcard{
  width: 400px;
  padding: 0;
}
.nav{
  width: 300px;
}
.f-features-list li{
  line-height: 23px;
  font-size: 15px;
  margin-bottom: 12px;
}
.f-para-element {
  font-size: 20px;
  margin-top: 8px;
  text-align: center;
}
}



