:root {
    --primary-color: #23b279;
  }
  
  @property --angle {
    syntax: '<angle>';  /* Add quotes and correct syntax */
    initial-value: 0deg;
    inherits: false;
  }
  
  .heading {
    position: relative;
  }
  
  .heading::after,
  .heading::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0.6rem 1.1rem;
    width: calc(100% + 3px);
    height: calc(100% + 3px);
    background-color: var(--primary-color);
    background-image: conic-gradient(
      from var(--angle),
      #23b279 0deg,
      #ffffff 90deg,
      #ffffff 180deg,
      #23b279 360deg
    );
    border-radius: 1.5rem;
    z-index: -1;
    animation: spin 4s linear infinite; /* Increased to 4s to include 1s pause */
}

@keyframes spin {
  0% {
    --angle: 360deg;
    opacity: 1;
  }
  75% { /* At 3s mark (75% of 4s) */
    --angle: 0deg;
    opacity: 1;
  }
  75.1% { /* Start disappearing */
    opacity: 0;
  }
  99.9% { /* Stay disappeared until end */
    opacity: 0;
  }
  100% {
    --angle: 360deg;
    opacity: 1;
  }
}
  .heading::before {
    filter: blur(0.2rem);
    opacity: 0.5;
  }
  
  /* @keyframes spin {
    from {
        --angle: 360deg;
      }
      to {
        --angle: 0deg;
      }
} */