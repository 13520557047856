.modal-overlay-crm {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor: pointer;
}

.modal-content-crm {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 80%; 
    max-height: 80%;
}

.close-button-crm {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 32px;
    cursor: pointer;
}

.modal-image-crm {
    max-width: 80%; 
    max-height: 80%; 
    object-fit: contain; 
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .modal-image-crm {
        max-width: 100%; 
        max-height: 100%; 
        object-fit: contain; 
        cursor: pointer;
    }
    
    .modal-content-crm {
        background-color: #fff;
        padding: 2px;
        border-radius: 10px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 100%; 
        max-height: 90%;
    }
}