 
  .payment-failure-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 40px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1s ease;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .failure-video {
    width: 300px;
    height: auto;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .message {
    font-size: 22px;
    color: #d9534f;
    margin-bottom: 20px;
  }
  
  .redirect-timer {
    font-weight: bold;
    font-size: 24px;
    color: #d9534f;
  }
  
  .home-btn {
    padding: 12px 24px;
    background-color: #007bff;
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .home-btn:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .payment-failure-container {
      padding: 20px;
    }
  
    .failure-video {
        width: 240px;
      }
  
    .message {
      font-size: 18px;
    }
  
    .home-btn {
      font-size: 16px;
      padding: 10px 20px;
    }
  }
  