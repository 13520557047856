.main-container-b2c{
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 70px; */
  background-color: #f2f9f6;
  background-size: cover;
  padding-bottom: 40px;
  font-family: Manrope !important;
}

@media (max-width: 767px) {
  .main-container-b2c {
  padding-top: 0px;
  width: 100vw;
  margin-top: 0px;
  padding-bottom: 0px;

  }
}

.upload-container-b2c {
  margin-top: 4rem; 
  font-size: 3.125rem; 
  font-weight: 550; 
  line-height: 1.08; 
  text-align: center;
  color: #000; 
  width: 45%;
  font-family: Manrope;
  contain: layout;
}

@media (max-width: 767px) {
  .upload-container-b2c {
    order: 0;
    text-align: center;
    color: var(--Black, #000);
    text-align: center;
    max-width: 546px;
    width: 85%;
    gap: 0px;
    font: 500 18px/34px; 
    margin-top: 2rem;
  }
  .image-pdf-upload-container{
    order: 2;
  }
}

@media (max-width: 767px) {
  .mobile-review-container-responsive {
    width: 100vw;
  }
}
@media (max-width: 767px) {
  .image-upload-container-b2c {
    width: 85%;
  }
}

.container-b2c {
  display: flex;
  gap: 5px;
  align-self: stretch;
  padding: 0 5px;
  width: 100%;
  max-width: 100%;
}

@media (max-width: 767px) {
  .container-b2c {
    display: flex;
    flex-direction: column;
    /* margin: 20px; */
    gap:2.5rem;
    /* width: 90%; */
    flex-wrap: wrap;
    order:3;
    margin-bottom: 3rem;
    margin-top: 3rem;
    max-width: none;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .container-b2c {
    font-size: 16px;
  }
}
  
.image-container-b2c {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.image-review-container-b2c {
  display: flex;
  gap: 2rem; 
  justify-content: center;
  align-self: center;
}

.review-image-b2c {
  flex-shrink: 0;
  width: 1.3rem; 
  height: 1.3rem; 
}

.text-container-b2c {
  margin-top: 0.75rem; 
  font-size: 0.875rem; 
  font-weight: 300; 
  line-height: 1.25rem;
  text-align: center;
  font-family: "Manrope" !important;
  color: #4a5568;
  /* width: 90%; */
}

.star-container-b2c {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.details-row-dash-mobile {
  display: flex;
  justify-content: space-between;  /* This aligns one child to the left and the other to the right */
}

.star-sub-container-b2c {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}


@media (max-width: 767px) {
  .image-card-container-b2c {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center; 
    order:4;
    margin-top: 20px;
  }
}

.icon-card-container-b2c {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem; 
  align-content: flex-start;
  align-self: stretch;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin-top: 2.5rem; 
  font-size: 1rem; 
  text-align: center;
  color: #000; 
  font-family: Manrope, -apple-system, Roboto, Helvetica, sans-serif !important;

}

@media (max-width: 767px) {
  .icon-card-container-b2c {
    display: flex;
    flex-direction: column;
    /* align-self: center; */
    margin: auto;
    /* justify-content: center; */
    /* align-items: center; */
    width: 100%;
    gap: 8px;
    /* max-width: 358px; */
    font-size: 16px;
    /* color: #000; */
    font-weight: 400;
    /* text-align: center; */
  }
}

.card-icon-container-b2c {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: Manrope, -apple-system, Roboto, Helvetica, sans-serif !important;
  /* align-self: flex-start; */
}

@media (max-width: 767px) {
  .card-icon-container-b2c {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
}

.image-card-icon-container-b2c {
  align-self: center;
  width: 2.5rem; 
  height: 2.5rem; 
}

@media (max-width: 767px) {
  .image-card-icon-container-b2c {
    width: 80px;
    height: 80px;
    aspect-ratio: 1;
    object-fit: cover;
  }  
}
.toggle-button-container {
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  border-radius: 10px; 
  overflow: hidden; 
  background-color: #f0f0f0; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  padding: 10px; 
  font-family: Manrope, -apple-system, Roboto, Helvetica, sans-serif !important;
}

.options-list-container-b2c {
  margin-bottom: 1.5rem;
}

@media (max-width: 767px) {
  .toggle-button-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.toggle-button {
  padding: 8px 20px; 
  font-size: 16px;
  text-transform: uppercase;
  background-color: #b7b7b7; 
  color: #fff; 
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease; 
  border-radius: 5px; 
  outline: none; 
  font-family: Manrope, -apple-system, Roboto, Helvetica, sans-serif !important;
}

.toggle-button.active {
  background-color: #007bff; /* Blue color */
  color: #fff; 
  box-shadow: 0 0 0 2px #007bff; 
  font-family: Manrope, -apple-system, Roboto, Helvetica, sans-serif !important;
}

/* .toggle-button:hover {
  background-color: rgba(0, 0, 0, 0.1); 
  transform: translateY(-2px); 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
} */

.button-text {
  color: #333; 
  font-family: Manrope, -apple-system, Roboto, Helvetica, sans-serif !important;
}

.pdf-button-b2c{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}


.pdf-locked-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin-top: 16px;
  max-width: 100%;
  background-color: white;
  border-radius: 20px;
  border: 1px solid #d1d5db; 
}

@media (max-width: 768px) {
  .pdf-locked-container {
    padding: 20px;
    max-width: 100%;
  }
}

.premium-upload-container {
  align-items: center;
  margin-top: 30px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid rgba(221, 221, 221, 1);
  background-color: var(--Gray-100, #f2f4f7);
  display: flex;
  width: 100%;
  max-width: 520px;
  flex-direction: column;
  font-weight: 400;
  padding: 16px 24px;
  margin:auto;
}

@media (max-width: 991px) {
  .premium-upload-container {
    padding: 20px;
  }
}

.premium-upload-icon {
  width: 40px;
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid rgba(234, 236, 240, 1);
}

.premium-upload-title {
  color: var(--Error-600, #d92d20);
  margin-top: 12px;
  font: 16px/143% Manrope;
  font-family: Manrope, -apple-system, Roboto, Helvetica, sans-serif !important;

}

.premium-upload-description {
  align-self: stretch;
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  margin-top: 4px;
  font-family: Manrope, -apple-system, Roboto, Helvetica, sans-serif !important;
  font: 14px/150%;
}

@media (max-width: 991px) {
  .premium-upload-description {
    max-width: 100%;
  }
}

.input-field-container-b2c {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0.2rem 0.2rem;
  width: 100%;
  font-size: 1rem; 
  font-weight: 500;
  background-color: #ffffff; 
  border-radius: 0.375rem;  
  border: 1px solid #d1d5db; 
  color: #6b7280; 
  max-width: 100%;
  font-family: Manrope, -apple-system, Roboto, Helvetica, sans-serif !important;

}

.input-field-b2c {
  width: 100%;
  border: none;
  background-color: transparent;  
  padding: 0.75rem;  
  font-size: 1rem;  
  color: #4b5563;  
  border-bottom: 0px solid #e5e7eb; 
  transition: border-color 0.2s ease-in-out;
  font-family: Manrope, -apple-system, Roboto, Helvetica, sans-serif !important;

}

.input-field-b2c:focus {
  outline: none; 
  border-color: #4f46e5; 
}

.image-wrapper {
  position: relative;
  width: 100px; 
  height: 100px; 
  margin-right: 10px;
}

.pdf-wrapper {
  position: relative;
  width: 100px; 
  height: 100px; 
  margin-right: 10px;
}

.pdf-delete-button {
  position: absolute;
  bottom: 35px;
  background: none;
  right: 35px;
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.pdf-delete-button:hover {
  background-color: rgba(255, 0, 0, 0.5);
}

.uploaded-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: rgba(255, 0, 0, 0.5);
}

.serial-number {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-family: Manrope, -apple-system, Roboto, Helvetica, sans-serif !important;

}

.uploaded-image-container {
  position: relative;
  margin-bottom: 10px;
  border: 2px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}

.pdf-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 6px;
  margin-top: 4px;
  max-width: 100%;
  width: 570px;
  height: 150px;
  background-color: #fff;
  border: 1px solid #d1d5db; 
  border-radius: 12px;
  overflow: auto;
}

.pdf-upload-container .upload-indicator {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
}

.pdf-upload-container .upload-indicator svg {
  width: 32px;
  height: 32px;
  fill: #5c5c5c; 
  font-family: Manrope, -apple-system, Roboto, Helvetica, sans-serif !important;

}

.pdf-upload-container .file-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.pdf-upload-container .file-container .upload-info {
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.pdf-upload-container .file-container .upload-info .upload-text {
  font-weight: 600;
  color: #059669; 
  font-family: Manrope, -apple-system, Roboto, Helvetica, sans-serif !important;

}

.pdf-upload-container .file-container .upload-info .upload-text span {
  margin-right: 4px;
}

.pdf-upload-container .file-container .file-wrapper {
  background-color: #f7fafc; 
  border-radius: 5px;
  font-family: Manrope, -apple-system, Roboto, Helvetica, sans-serif !important;

}

.pdf-upload-container .file-container .file-wrapper .pdf-info {
  display: flex;
  justify-content: space-between;
  align-items: center; 
  padding: 10px; 
}

.pdf-upload-container .file-container .file-wrapper .pdf-info .pdf-name {
  flex-grow: 1; 
  margin-right: 10px; 
}

.pdf-upload-container .file-container .file-wrapper .delete-button {
  background-color: #fb3232; 
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-family: Manrope, -apple-system, Roboto, Helvetica, sans-serif !important;

}

.pdf-container-b2c{
  display: flex;
  gap: 5px;
}

.review-container-name-b2c{
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-button-b2c{
  cursor:pointer;
  margin-bottom: 3rem;
}


@keyframes border-glow {
  0% {
    border-color: #f52424; 
    box-shadow: 0 0 5px rgba(245, 36, 36, 0.5); 
  }
  50% {
    border-color: #ff8c00; 
    box-shadow: 0 0 10px rgba(255, 140, 0, 0.5); 
  }
  100% {
    border-color: #f52424; 
    box-shadow: 0 0 5px rgba(245, 36, 36, 0.5); 
  }
}

.special-image-container{
  display: flex;
  justify-content:space-around;
}

.special-image-wrapper {
  /* display: flex; */
  /* align-items: center; */
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 300px; 
  height: 600px;
  border: 2px solid #f52424;
  border-radius: 8px; 
  overflow: hidden; 
  box-shadow: 0 0 5px rgba(245, 36, 36, 0.5);
  animation: pulse 2s ease-in-out infinite, border-glow 3s ease infinite;
  transition: transform 0.3s ease; 
  font-family: Manrope, -apple-system, Roboto, Helvetica, sans-serif !important;
}
@media screen and (max-width: 767px) {
  .special-image-container {
    flex-direction: column;
    align-items: center;
  }
}

.special-uploaded-image {
  width: 300px;  
  height: 600px; 
  display: block; 
  object-fit: cover; 
}

.special-serial-number {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: #f52424; 
  color: #ffffff;
  padding: 2px 8px;
  border-radius: 20px; 
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase; 
  letter-spacing: 1px; 
  font-family: Manrope, -apple-system, Roboto, Helvetica, sans-serif !important;

}

.special-image-wrapper:hover {
  transform: scale(1.05); 
}


.special-serial-number {
  animation: pulse 1s ease-in-out infinite alternate; 
}

.error-message-b2c {
  color: #f44336; 
  font-size: 14px;
  margin-top: 5px;
  padding: 5px;
  background-color: #ffebee; 
  border: 1px solid #f44336; 
  border-radius: 4px;
  font-family: Manrope, -apple-system, Roboto, Helvetica, sans-serif !important;
}

.info-message-b2c {
  color: #46f958; 
  font-size: 14px;
  margin-top: 5px;
  padding: 5px;
  background-color: #312b2b; 
  border: 1px solid #279f29; 
  border-radius: 4px;
  font-family: Manrope, -apple-system, Roboto, Helvetica, sans-serif !important;
}

.error-message-b2c::before {
  content: "⚠ "; 
}

.info-message-b2c::before{
  content: "✔ ";
}

.input-field-container-b2c {
  position: relative;
}

.input-field-container-b2c .error-message {
  position: absolute;
  top: 100%;
  left: 0;
}

.review-container-b2c-tr{
  font-family: Manrope, -apple-system, Roboto, Helvetica, sans-serif !important;
}

.review-container-b2c-tr{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pdf-list-container_ {
  max-height: 100px;
  overflow-y: auto; 
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.pdf-list_ {
  display: flex;
  flex-direction: column;
}

.pdf-item_ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  margin-bottom: 4px;
  background-color: #f7f7f7;
  border-radius: 5px;
}

.pdf-name_ {
  flex: 1;
  margin: 0;
  font-size: 12px; 
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pdf-delete-button_ {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.pdf-delete-button_:hover {
  color: #fb3232;
}

@media screen and (max-width: 767px) {
  .optional-container-b2c-mb{
    display: flex;
    flex-direction: column;
  }
}

.premium-upload-container a {
  color: blue;
  text-decoration: underline;
}
