.n-navbar-header {
    background-color: #fefefe;
    box-shadow: 0 9px 12px rgba(0, 0, 0, 0.04);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    padding: 0 100px 0 0;
    align-items: center;
    /* gap: 40px 100px; */
    justify-content: space-between;
    /* flex-wrap: wrap; */
    position: fixed;
    width: 100%;
    z-index:1000;
  }

  .n-nav-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 40px 100px;

  }

  @media (max-width: 768px) {
    .n-navbar-header {
      display: none;
    }
  }
  
  @media (max-width: 991px) {
    .n-navbar-header {
      padding-right: 20px;
    }
  }
  
  .n-navbar-logoContainer {
    align-self: stretch;
    display: flex;
    min-width: 240px;
    align-items: center;
    gap: 16px;
    justify-content: start;
    width: 329px;
    margin: auto 0;
  }
  
  .n-navbar-homeIconWrapper {
    border-radius: 4px;
    background-color: #fff;
    align-self: stretch;
    display: flex;
    min-height: 70px;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 71px;
    cursor: pointer;
    padding: 0 16px;
    border: 1px solid transparent;
  }
  
  .n-navbar-icon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    align-self: stretch;
    margin: auto 0;
  }
  
  .n-navbar-logo {
    aspect-ratio: 3.79;
    object-fit: contain;
    object-position: center;
    width: 208px;
    align-self: stretch;
    margin: auto 0;
  }
  
  .n-navbar-navigationContainer {
    align-self: stretch;
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: start;
    margin: auto 0;
  }
  
  .n-navbar-dashboardButton {
    align-self: stretch;
    border-radius: 6px;
    background-color: #e4ffe0;
    min-height: 42px;
    color: #1f5b43;
    text-align: center;
    width: 120px;
    margin: auto 0;
    padding: 10px;
    font: 400 16px/1 Roboto, sans-serif;
    border: 1px solid rgba(31, 91, 67, 0.1);
  }
  
  @media (max-width: 991px) {
    .n-navbar-dashboardButton {
      white-space: normal;
    }
  }
  
  .n-navbar-userProfile {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: end;
    margin: auto 0;
  }
  
  .n-navbar-profileContainer {
    align-self: stretch;
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: end;
    width: 42px;
    margin: auto 0;
  }
  
  .n-navbar-profileImageWrapper {
    align-self: stretch;
    display: flex;
    width: 42px;
    align-items: center;
    gap: 6px;
    justify-content: center;
    margin: auto 0;
  }
  
  .n-navbar-profileImage {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 42px;
    border-radius: 100px;
    align-self: stretch;
    margin: auto 0;
  }
  
  .n-navbar-visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  .n-navbar-dropdownMenu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.15);
    padding: 12px;
    z-index: 10;
    width: 220px;
    display: flex;
    gap: 4px;
    flex-direction: column;
    animation: dropdownFadeIn 0.3s ease;
    font-size: 18px;
}

.n-navbar-dropdownMenu button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 14px;
    background: none;
    border: none;
    text-align: left;
    font-size: 14px;
    color: #444;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s ease, color 0.2s ease;
}

.n-navbar-dropdownMenu button:hover {
    background-color: #f3f3f3;
    color: #000;
}

.n-navbar-dropdownMenu button:focus {
    outline: none;
    background-color: #eaeaea;
}

.n-navbar-dropdownMenu button + button {
    margin-top: 6px;
}

.n-navbar-dropdownMenu button .icon {
    margin-right: 10px;
    font-size: 20px;
    color: #888;
    transition: color 0.2s ease;
}

.n-navbar-dropdownMenu button:hover .icon {
    color: #555;
}

.n-navbar-dropdownMenu .separator {
    height: 1px;
    background-color: #ddd;
    margin: 10px 0;
}

.n-navbar-dropdownMenu .menu-header {
    font-weight: bold;
    font-size: 13px;
    color: #666;
    padding: 8px 14px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

@keyframes dropdownFadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media screen and (max-width: 768px) {
  .collectorbabu-logo {
    width: 100px;
    height: 30px;
    /* padding: 20px; */
  }
  .n-navbar-homeIconWrapper{
    display: none;
  }
  .n-navbar-header{
    gap: 0px;
  }
  .n-navbar-logoContainer{
    width: 100%;
  }
}

.collectorbabu-logo{
  height: 60px;
  width:200px;
}

.profile-image{
  width: 40px;
  height:40px;
  border-radius: 30px;
  border: 4px solid green;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #D3D3D3; 
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  display: block;
  text-decoration: none;
  white-space: nowrap;
}

.dropdown-content a:hover {
  background-color: #ddd; 
}