.left-pane {
    /* flex: 1 1 20%; */
    background-color: #d9d9d9;
    min-height: 100vh;
}

li {
    list-style: none;
}

hr {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    border-width: 0.2rem;
    color: #7d7c7c;
}

.navbar-dashboard-m-btn {
    background-color: #5fe3ae;
    color: #424141;
    text-transform: none;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    box-shadow: none;
    transition: background-color 0.3s ease;
    margin-left: 3px;
    margin-right: 3px;

  }
  
  .navbar-dashboard-m-btn:hover {
    background-color: #ffe5e1;
  }
  