.ai-mock-main-container{
  display: flex;
  width: 100%;
  background-size: cover;
  height: 100vh;
  background-color: #262626;
  padding: 30px;
}

@media screen and (max-width:778px) {
  .ai-mock-main-container{
  flex-direction: column;
  height: 100%;  
  }
  .video-call{
    width: 100% !important;
    height: 100vh;
  }
  .participant-wrapper{
    width: 100px !important;
    height: 100px !important;
  }
  .participant-card{
    width: 100px !important;
    height: 100px !important;
  }
  .participant-video{
    height: 112px !important;
    width: 159px;
  }
  .question-container{
    width: 100% !important;
    margin: 0 !important;
    margin-top: 15px !important;
  }
  .participant-info{
    top:0px !important;
  }
  .main-video{
    margin-top: 45px !important;
  }
  .participants-grid{
    gap:35px !important;
  }
  .participant-name{
    padding: 4px !important;
  }
  .video-feed{
    height: 410px;
  }
}

.video-call {
      border-radius: 20px;
      background-color: rgba(51, 51, 51, 1);
      display: flex;
      /* max-width: 960px; */
      width: 70%;
      flex-direction: column;
      padding: 30px;
    }

    .participants-grid {
      display: flex;
      /* min-height: 180px; */
      width: 100%;
      justify-content: space-between;
      align-items: center;
      /* gap: 12px; */
      flex-wrap: wrap;
    }

    /* .participant-card {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 168px;
    } */

    .participant-wrapper {
      height: 190px;
      width: 190px;
      display: flex;
      flex-direction: column;
      border-radius: 16px;
      position: relative;
      aspect-ratio: 1.16;
      width: 100%;
      padding: 89px 12px 12px;
    }

    

    .participant-video {
      position: absolute;
      inset: 0;
      height: 184px;
      width: 203px;
      object-fit: cover;
      object-position: center;
      border-radius: 16px;
    }

    .participant-info {
      position: relative;
      display: flex;
      width: 100%;
      /* align-items: center; */
      /* gap: 40px; */
      top: 52px;
      justify-content: space-between;
    }

    .name-container {
      align-self: stretch;
      display: flex;
      min-height: 30px;
      max-width: "120px";
      align-items: start;
      white-space: nowrap;
      /* background-color: red; */
    }

    .participant-name {
      align-self: stretch;
      border-radius: 61px;
      background-color: rgba(0, 0, 0, 0.3);
      min-height: 25px;
      padding: 4px 20px;
      color:rgba(255, 255, 255, 1) ;
      font: 500 10px/1 ;
    }

    .audio-indicator {
      border-radius: 32px;
      background-color: rgba(0, 0, 0, 0.3);
      align-self: stretch;
      width: 30px;
      height: 30px;
    }

    .main-video {
      border-radius: 0;
      display: flex;
      margin-top: 25px;
      width: 100%;
      flex-direction: column;
    }

    .video-container {
      display: flex;
      flex-direction: column;
      border-radius: 20px;
      position: relative;
      max-height: 470px;
      width: 100%;
      padding: 24px;
    }

    .video-feed {
      position: absolute;
      inset: 0;
      height: 430px;
      width: 100%;
      object-fit: cover;
      border-radius: 20px;
    }

    .recording-status {
      position: relative;
      border-radius: 30px;
      background-color: #4F4F4F;
      color: #ffffff;
      width: 140px;
      align-self: start;
      display: flex;
      min-height: 40px;
      align-items: center;
      gap: 12px;
      padding: 8px 10px;
    }

    .record-indicator {
      align-self: stretch;
      display: flex;
      width: 24px;
      margin: auto 0;
    }
    .record-icon-wrapper {
      background-color: rgba(255, 255, 255, 0.63);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      padding: 0 5px;
    }

    .record-icon {
      background-color: rgba(235, 87, 87, 1);
      border-radius: 50%;
      width: 9px;
      height: 10px;
    }

    .timer {
      color: rgba(255, 255, 255, 1);
      font: 500 16px ;
    }

    .user-info {
      position: relative;
      display: flex;
      margin-top: 302px;
      width: 100%;
      align-items: center;
      gap: 100px;
      justify-content: space-between;
    }

    .user-name {
      border-radius: 61px;
      background-color: #4F4F4F;
      min-height: 40px;
      width: 100px;
      color: rgba(255, 255, 255, 1);
      font: 500 20px/1 ;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .audio-status {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      height: 50px;
      border-radius: 61px;
      background-color: rgba(75, 69, 64, 0.5);
      min-height: 40px;
      width: 110px;
    }

    .audio-status-single{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      height: 20px;
      border-radius: 61px;
      background-color: rgba(75, 69, 64, 0.5);
      min-height: 40px;
      width: 70px;
    }
    
    .ball {
      width: 10px;
      height: 10px;
      background-color: whitesmoke;
      border-radius: 50%;
      animation: bounce 1s infinite ease-in-out;
    }
    
    .ball:nth-child(2) {
      animation-delay: 0.2s;
    }
    
    .ball:nth-child(3) {
      animation-delay: 0.4s;
    }
    
    @keyframes bounce {
      0%, 100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-20px);
      }
    }
    

    .controls-container {
      border-radius: 86px;
      background-color: rgba(38, 38, 38, 1);
      display: flex;
      margin-top: 25px;
      width: 100%;
      justify-content: center;
      padding-top: 10px;
      padding-bottom: 20px;
      position: fixed; /* Fixes the container to the window */
      bottom: 0; /* Aligns it to the bottom of the window */
      left: 0; 
      z-index: 1000;
    }

    .controls-wrapper {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    .control-button {
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      transition: opacity 0.2s ease;
    }

    .control-button:hover {
      opacity: 0.8;
    }

    .control-button:focus {
      outline: 2px solid;
      border-radius: 32px;
    }

    .control-button.active {
      opacity: 1;
    }

    .control-icon {
      aspect-ratio: 1;
      object-fit: contain;
      width: 48px;
      border-radius: 32px;
    }

    @media (max-width: 991px) {
      .video-call {
        padding: 0 20px;
      }
      .participants-grid {
        max-width: 100%;
      }
      .participant-wrapper {
        padding-top: 100px;
      }
      .video-container {
        max-width: 100%;
        padding: 0 20px;
      }
      .user-info {
        max-width: 100%;
        margin-top: 310px;
      }
      .controls-container {
        max-width: 100%;
        padding: 0 20px;
      }
    }

    .stop-video-button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ff4d4d;
      color: white;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      font-size: 16px;
      height: 55px;
      width: 55px;
    }
    
    .stop-video-button:hover {
      background-color: #ff1a1a;
    }
    
    .participant-card {
      /* position: absolute; */
      width: 200px;
      height: 181px;
      border-radius: 20%;
      background-size: cover;
      /* overflow: hidden; */
      z-index: 10;
      pointer-events: none;
      transition: transform 0.3s ease, box-shadow 0.3s ease, filter 0.3s ease, opacity 0.3s ease;
      background: linear-gradient(145deg, #ffffff, #f0f0f0); /* Light gradient */
      box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15), 0 0 6px rgba(255, 255, 255, 0.3); /* Soft shadow */
      opacity: 0.95;
    }
    
    /* Hover effect */
    .participant-card:hover {
      cursor: pointer;
      transform: scale(1.1); /* Slightly enlarge on hover */
      filter: brightness(1.2) contrast(1.1);
      box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2), 0 0 8px rgba(255, 255, 255, 0.4); /* Glow effect */
    }
    
    /* Active avatar (when clicked or in focus) */
    .participant-active {
      border: 3px solid #4caf50; /* Green border to highlight active state */
      background: linear-gradient(145deg, #eaffea, #d4f5d4); /* Light green tint */
      box-shadow: 
        0 12px 25px rgba(0, 0, 0, 0.25),     /* Stronger shadow for depth */
        0 0 20px rgba(76, 175, 80, 0.9),     /* Green glow around avatar */
        inset 0 2px 4px rgba(255, 255, 255, 0.7); /* Inner glow for depth */
      transform: scale(1.15) ; /* Slight rotation to enhance the effect */
      filter: brightness(1.3); /* Brighter active state */
      animation: activePulse 1.5s infinite; /* Pulsing animation */
      opacity: 1; /* Ensure full visibility */
      z-index: 20; /* Bring to front when active */
    }
    
    /* Pulse animation for active avatar */
    @keyframes activePulse {
      0% {
        box-shadow: 
          0 12px 25px rgba(0, 0, 0, 0.25), 
          0 0 20px rgba(76, 175, 80, 0.6),
          inset 0 2px 4px rgba(255, 255, 255, 0.7);
      }
      50% {
        box-shadow: 
          0 14px 30px rgba(0, 0, 0, 0.3),
          0 0 28px rgba(76, 175, 80, 0.8),
          inset 0 3px 6px rgba(255, 255, 255, 0.8);
      }
      100% {
        box-shadow: 
          0 12px 25px rgba(0, 0, 0, 0.25), 
          0 0 20px rgba(76, 175, 80, 0.6),
          inset 0 2px 4px rgba(255, 255, 255, 0.7);
      }
    }
    
    /* Adding glow and depth ring around the avatar */
    .participant-active:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      background: radial-gradient(
        rgba(76, 175, 80, 0.7),
        transparent 70%
      );
      transform: translate(-50%, -50%);
      animation: glowEffect 2s infinite;
    }
    
    /* Glow effect */
    @keyframes glowEffect {
      0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0.8;
      }
      50% {
        transform: translate(-50%, -50%) scale(1.25);
        opacity: 1;
      }
      100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0.8;
      }
    }
    
    /* Lighting effect for extra realism */
    .participant-active {
      animation: blink 1s infinite;
      background: linear-gradient(120deg, rgba(255, 255, 255, 0.8), rgba(76, 175, 80, 0.5)); /* Gradient to simulate lighting */
      box-shadow: 
        0 15px 25px rgba(0, 0, 0, 0.3), 
        0 0 30px rgba(76, 175, 80, 0.6), 
        inset 0 2px 6px rgba(255, 255, 255, 0.5); /* Adding soft lighting around avatar */
    }
    
    /* Optional: Adding realistic animation when the avatar is clicked or activated */
    @keyframes realisticShake {
      0% {
        transform: translateX(0);
      }
      25% {
        transform: translateX(-5px);
      }
      50% {
        transform: translateX(5px);
      }
      75% {
        transform: translateX(-5px);
      }
      100% {
        transform: translateX(0);
      }
    }
    
    .participant-active:active {
      animation: realisticShake 0.3s ease-out; /* Shake effect when active */
    }
    
    .toggle-video-button {
      background-color: #007bff; /* Primary color */
      color: #fff; /* White text */
      border: none; /* No border */
      border-radius: 5px; /* Rounded corners */
      padding: 10px 20px; /* Padding for size */
      font-size: 16px; /* Text size */
      cursor: pointer; /* Pointer cursor on hover */
      transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
    }
    
    .toggle-video-button:hover {
      background-color: #0056b3; /* Darker blue on hover */
      transform: scale(1.05); /* Slightly increase size on hover */
    }
    
    .toggle-video-button:active {
      background-color: #003f7f; /* Even darker blue when clicked */
      transform: scale(0.95); /* Slightly decrease size on click */
    }
    
    .toggle-video-button:disabled {
      background-color: #ccc; /* Gray background for disabled state */
      cursor: not-allowed; /* Not-allowed cursor for disabled state */
    }
    
    .blinking {
      animation: blink 1s infinite;
    }
    
    @keyframes blink {
      50% {
        opacity: 0.5;
      }
    }
    
    .question-container {
      border-radius: 20px;
      background-color: rgba(51, 51, 51, 1);
      display: flex;
      max-width: 480px;
      width: 30%;
      flex-direction: column;
      overflow: hidden;
      align-items: center;
      font-family: Manrope, sans-serif;
      justify-content: start;
      margin: 0px 10px 0px 10px;
      padding: 16px 16px 29px;
    }
    .header-ai-mock {
      border-radius: 8px;
      display: flex;
      max-width: 100%;
      width: 322px;
      flex-direction: column;
      font-size: 18px;
      color: rgba(205, 209, 214, 1);
      font-weight: 600;
      white-space: nowrap;
      line-height: 33px;
    }
    .title-ai-mock {
      border-radius: 8px;
      background-color: rgba(38, 38, 38, 1);
      border-bottom-width: 1px;
      padding: 12px 25px 5px;
      color: #f0f0f0;
      border: 1px solid rgba(38, 38, 38, 1);
    }
    .questions-list {
      display: flex;
      margin-top: 16px;
      height: 784px;
      width: 100%;
      max-width: 322px;
      flex-direction: column;
      overflow: auto;
      font-size: 16px;
      color: rgba(226, 226, 226, 1);
      font-weight: 400;
      line-height: 26px;
      justify-content: start;
    }
    /* For WebKit browsers */
.questions-list::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.questions-list::-webkit-scrollbar-track {
  background: #2e2e2e; /* Background of the scrollbar track */
}

.questions-list::-webkit-scrollbar-thumb {
  background: #555; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners */
}

.questions-list::-webkit-scrollbar-thumb:hover {
  background: #888; /* Color when hovering over the scrollbar */
}

/* For Firefox */
.questions-list {
  scrollbar-width: thin;
  scrollbar-color: #555 #2e2e2e;
}
    .question-item {
      flex: 1;
      border-radius: 8px;
      background-color: rgba(38, 38, 38, 1);
      margin-top: 12px;
      width: 100%;
      gap: 8px;
      padding: 4px 6px;
      cursor: pointer;
      transition: background-color 0.2s ease;
    }
    .question-item:first-child {
      margin-top: 0;
    }
    .question-item:hover {
      background-color: rgba(48, 48, 48, 1);
    }
    .question-item:focus {
      outline: 2px solid rgba(205, 209, 214, 1);
      outline-offset: -2px;
    }
    .question-item.selected {
      background-color: rgba(58, 58, 58, 1);
      border: 1px solid rgba(205, 209, 214, 0.5);
    }
    .empty-question {
      border-radius: 8px;
      background-color: rgba(38, 38, 38, 1);
      display: flex;
      min-height: 0px;
      margin-top: 12px;
      width: 100%;
      gap: 8px;
      padding: 4px 0;
    }

    /* .ai-connect-button {
      background-color: black;
      color: white;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      transition: background-color 0.3s ease, transform 0.2s ease;
    } */
    
    /* .ai-connect-button:hover {
      background-color: #333;
      transform: scale(1.05);
    } */
    
    /* .ai-connect-button:active {
      background-color: #555;
      transform: scale(0.95);
    } */
    
    .ai-connect-button {
      margin-top: 10px;
      padding: 10px 20px;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
    }
    
.connect-button{
  background-color: #4caf50;
}
    
.disconnect-button{
  background-color: #ff4d4d;
}