.bottom-navbar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #F8FAFC;
    border-top: 2px solid purple;
    padding: 10px 0;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: gray;
    font-size: 12px;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .nav-item span {
    margin-top: 5px;
  }
  
  .nav-item.active {
    color: #23B279;
  }
  
  .nav-item:hover {
    color: black;
  }
  
  
  @media (min-width: 768px) {
    .bottom-navbar {
      display: none;
    }
  }
  
  .menu-popup-bottom-nav {
    position: absolute;
    bottom: 60px;
    right: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 1100;
    width: 45%;
  }
  
  .menu-item-bottom-nav {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding: 10px;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .menu-item-bottom-nav:hover {
    background-color: #f0f0f0;
    color: #007bff;
  }
  
  .menu-item-label-bottom-nav {
    margin-left: 8px;
    font-size: 14px;
    white-space: nowrap;
  }
  