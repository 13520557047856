.dashboard-container-sub {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 80%;
    margin-top: 80px !important;
    margin: auto;
    height: 70%;
    /* height:100vh; */
    /* overflow: auto; */
  }
  
  .container-dashboard-new {
      display: flex;
      /* flex-direction: row; */
      justify-content: center;
      /* padding: 0 56px 50px; */
    }
  
    
    
    .title {
      color: #1f1f1f;
      text-align: center;
      font: 500 30px Roboto, sans-serif;
    }
    
    .titleHighlight {
      font-family: Manrope, sans-serif;
      font-weight: 600;
      color: #2f8965;
    }
    
    .titleText {
      font-family: Manrope, sans-serif;
      font-weight: 600;
    }
    
    @media screen and (max-width: 768px ) {
      .sidebar-container-mobile{
        display: none;
      }
      .featureIcon {
        margin-left: 10px;
      }
      .container {
        padding: 0 20px;
      }
      .title {
        max-width: 100%;
      }
      .unhide-sidebar-mbv{
        display:none;
      }
  
      .cardGrid {
        max-width: 100%;
        margin-top: 40px;
      }
      
      .featureText {
        /* width: 30px !important;
        background-color: red;
        /* overflow-x: auto; */
        /* color: #3c4852;
        margin: auto 0;
        font: 400 10px Manrope, sans-serif;  */
  
        color: #3c4852;
            width: 146px;
            margin: auto 0;
            font: 400 14px Manrope, sans-serif;
      }
  
      .featureCard {
        border-radius: 6px;
        box-shadow: 0 9px 12px rgba(0, 0, 0, 0.03);
        display: flex;
        flex-direction: column;
        width: 140px !important;
        height: 139px !important;
        margin: auto 0;
        padding: 16px 0;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
      .featureIcon {
        aspect-ratio: 1;
        object-fit: contain;
        object-position: center;
        width: 50px !important;
        margin-left: 16px;
      }
      .featureText {
        width: 90px !important;
  
        color: #3c4852;
        margin: auto 0;
        font: 400 10px Manrope, sans-serif !important;
      }
      .moreButton{
          /* border-radius: 25px;
          background-color: #e4ffe0;
          display: flex;
          align-items: center;
          justify-content: center;
         
          margin: auto 0;
          padding: 0px;
          cursor: pointer;
          border: none;
          transition: background-color 0.2s ease; */
          width: 33.5px !important;
          height: 23.5px !important;
          border-radius: 25px;
            background-color: #e4ffe0;
            display: flex;
            min-height: 14px;
            align-items: center;
            justify-content: center;
            /* width: 14px;
            height: 14px; */
            margin: auto 0;
            padding: 0 2px;
            border: none;
            cursor: pointer;
      }
  
      .featureInfo {
        /* display: flex;
        margin-top: 0px !important;
        width: 52px !important;
  
        align-items: center;
        gap: 0px 0px !important;
        justify-content: space-between;
        padding: 0 16px; */
        display: flex;
        margin-top: 15px;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        gap:3px !important;
        padding:0px !important;
        padding-left: 10px !important;
      }
    }
  
  
  
  
    
    .cardGrid {
      align-self: center;
      display: flex;
      background-color: transparent;
      flex-direction: row !important;
      margin-top: 49px;
      /* align-items: center; */
      gap: 16px;
      justify-content: center;
      flex-wrap: wrap;
    }
    
    
    
    .featureCard {
      border-radius: 6px;
      background-color: #fff;
      box-shadow: 0 9px 12px rgba(0, 0, 0, 0.03);
      display: flex;
      flex-direction: column;
      cursor: pointer;
      width: 250px;
      height: 200px;
      margin: auto 0;
      padding: 16px 0;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
    
    .featureIcon {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 80px;
      margin-left: 16px;
    }
    
   
    .featureInfo {
      display: flex;
      margin-top: 12px;
      width: 80%;
      align-items: center;
      gap: 15px;
      justify-content: space-between;
      padding: 0 16px;
    }
    
    .featureText {
      color: #3c4852;
      width: 155px;
      margin: auto 0;
      font: 400 18px Manrope, sans-serif;
      flex-shrink: 0;
    }
    
    .moreButton {
      border-radius: 25px;
      background-color: #e4ffe0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      margin: auto 0;
      padding: 7px;
      cursor: pointer;
      border: none;
      transition: background-color 0.2s ease;
    }
    
    .moreButton:hover {
      background-color: #d0ffc9;
    }
    
    .moreButton:focus {
      outline: 2px solid #2f8965;
      outline-offset: 2px;
    }
    
    .moreIcon {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 36px;
    }
  
    .container-side-and-center{
      display: flex;
      /* justify-content:center */
    }
  
    .center-dashboard-container{
      display: flex;
      flex-direction: column;
      align-items:center;
      justify-content: center;
      padding: 0px 68px 50px 68px;
  
      /* background-color:red; */
    }
  
    /* @media screen and (max-width: 768px) {
      .sidebar-container-mobile{
        display: none;
      }
      
    } */