.message {
  margin: 10px 0;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.pages-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.page-item {
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
}

.pdf-crop-delete {
  position: absolute;
  top: 5px;
  right: 5px;
  background: red;
  color: white;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.page-number {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 2px 5px;
  font-size: 12px;
}

.crop-overlay {
  border: 2px dashed rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 0, 0.3); 
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.crop-overlay {
  position: absolute;
  border: 2px dashed rgba(0, 0, 0, 0.5);
  pointer-events: none;
  box-sizing: border-box;
}


.pages-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-item {
  position: relative;
  margin: 10px 0;
}

.pdf-crop-delete {
  position: absolute;
  top: 10px;
  right: 10px;
  background: red;
  color: white;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.page-number {
  text-align: center;
  margin-top: 5px;
}

.crop-overlay {
  position: absolute;
  border: 2px dashed rgba(0, 0, 0, 0.5);
  pointer-events: none;
  box-sizing: border-box;
}

.crop-canvas {
  display: none;
}
