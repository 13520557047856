*{
    margin: 0;
    padding: 0;
}
.base{
    display: flex;
    width: 100%;
    height:  100vh;
    justify-content:center;
    align-items: center;
    background-color: #E2E8F0; 
}
.box{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 70%;
    width: 60%;
    border: 1px solid transparent;
    border-radius: 0.5rem;
}

.left{
    background-color: white;
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}
.right{
    background-color: rgb(172, 225, 172);
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    justify-items: center;
}


@media screen and (max-width: 768px) {
    .box {
        width: 100%; /* Set box width to 100% on tablet screens */
    }
}

@media screen and (max-width: 480px) {
    .box {
        width: 100%; /* Set box width to 100% on mobile screens */
        height: 100%; /* Set box height to 100% on mobile screens */
        flex-direction: column; /* Stack left and right divs vertically on mobile screens */
    }

    .left {
        display: none; /* Hide the left div on mobile screens */
    }

    .right {
        width: 100%; /* Set right div width to 100% on mobile screens */
        height: 100%; /* Set right div height to 100% on mobile screens */
        /* background-color: rgb(172, 225, 172);
        background-image: url('./7140416_3487927.jpg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-blend-mode:   multiply; */
    }
    .right > .logo{
        margin-top: 30vw;
    }
}