  
  .container {
    text-align: center;
    background-color: white;
    padding: 50px;
    border-radius: 15px;
    width: 800px;
    /* height: 800px; */
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }
  
  .icon {
    position: relative;
    margin: 0 auto 20px;
    width: 100px;
    height: 100px;
    animation: bounce 1.2s infinite;
  }
  
  .icon svg {
    stroke: #34c759;
    stroke-width: 2;
    width: 80px;
    height: 80px;
  }
  
  .checkmark {
    position: absolute;
    bottom: -10px;
    right: -10px;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
    border: 3px solid #34c759;
    animation: pop 0.4s ease-out 0.8s forwards;
  }
  
  .checkmark svg {
    stroke: #34c759;
    stroke-width: 2;
    width: 30px;
    height: 30px;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  @keyframes pop {
    0% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  }
  
  h1 {
    font-size: 24px;
    color: #34c759;
    margin: 20px 0 10px;
  }
  
  p {
    color: #555;
    font-size: 14px;
    margin: 5px 0;
  }
  
  .home-button {
    margin-top: 20px;
    padding: 10px 25px;
    background-color: #34c759;
    color: white;
    border: none;
    border-radius: 25px;
    font-size: 18px;
    font-weight: 900;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(52, 199, 89, 0.4);
    transition: background-color 0.3s ease;
  }
  
  .home-button:hover {
    background-color: #2dbe4f;
  }
  