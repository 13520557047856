footer {
  width: 100%;
  position: relative;
  bottom: 0;
  background: #2E2E2E; 
  color: #fff;
  padding: 0px 0 30px; 
  border-top-left-radius: 125px;
  font-size: 14px; 
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.footer-row {
  width: 85%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-col {
  flex-basis: 25%;
  padding: 15px; 
}

.footer-col:nth-child(2),
.footer-col:nth-child(3) {
  flex-basis:15%;
}

.footer-heading {
  width: 100%;
  margin-bottom: 30px;
  font-size: 24px; 
  color: #fff;
}

.footer-col h3 {
  position: relative;
  margin-bottom: 40px;
  width: fit-content;
}

.footer-email {
  width: fit-content;
  border-bottom: 1px solid #ccc;
  margin: 10px 0;
}

.footer-list-items li a {
  text-decoration: none;
  color: #D3D3D3;
  font-size: 15px;
  transition: color 0.3s ease; 
}

.footer-list-items li a:hover {
  color: #007bff;
}

.under-line{
  width: 100%;
  height: 5px;
  background: #767676;
  border-radius: 3px;
  position: absolute;
  top:25px;
  left:0;
  overflow: hidden;
}
.under-line span{
  width: 15px;
  height: 100%;
  background: #fff;
  border-radius: 3px;
  position: absolute;
  top:0;
  left:10px;
  animation:moving 2s linear infinite;
}
@keyframes moving {
  0% {
      left: -20px;
      background: #2E2E2E; 
  }
  100% {
      left: 100%;
      background: #D3D3D3;
  }
}

form {
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  margin-bottom: 50px;
}

form input {
  width: 100%;
  background: transparent;
  color: #D3D3D3;
  border: 0;
  outline: none;
  font-size: 14px; 
}

form button {
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}

.social-icons {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  color: #CCCCCC;
  background: #5F9EA0;
  margin-left: 15px;
  transition: background 0.3s ease;
  cursor: pointer; 
}

.social-icons:hover {
  background: #0056b3;
}

hr {
  width: 90%;
  border: 0;
  border-bottom: 1px solid #fff;
  margin: 5px auto;

}

.copy-right {
  text-align: center;
  font-size: 12px; 
  padding-top: 10px;
}

@media (max-width: 768px) {
   .footer-col {
    flex-basis: 100%;
    margin-bottom: 20px;
  }

  .footer-email {
    margin-top: 10px;
  }

  footer {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      /* width:100vh; */
      display: flex;
      justify-content: center;
      align-items: center;
    }
  /*
    .footer-container {
      width: 100%; 
      padding: 0 20px; 
      box-sizing: border-box;
      background-size: cover;
    }*/

    
.footer-row {
  flex-direction: column; 
}

.footer-col {
  flex-basis: 100%; 
  padding: 10px 0; 
} 

.footer-heading {
  margin-bottom: 10px;
}
}

.social-col{
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
  flex-direction: column;
  align-items: center;
  text-align: center;
}


.ftp{
  color: #fff;
  padding: 3px;
}

.social{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}