:root {
  --primary-color: #23b279;
}

.answer-evaluation-content {
  display: flex;
  background-size: cover;
  height: 100vh;
  width: 100%;
}

answer-evaluation-container {
  height: 100vh;
  background-size: cover;
}

.answer-evaluation-container-sub {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 70%;
  margin-top: 80px;
  margin: auto;
  height: 70%;
  /* height:100vh; */
  /* overflow: auto; */
}

.answer-e-upload-title {
  color: rgba(31, 31, 31, 1);
  text-align: center;
  margin: 0;
  font-size: 30px;
  font-weight: bold;
  /* width:60%; */
}
.answer-e-highlight {
  color: rgba(47, 137, 101, 1);
  font-weight: 600;
}

.evaluation-container {
  border-radius: 30px;
  background-color: #fff;
  box-shadow: 0 9px 12px rgba(0, 0, 0, 0.04);
  display: flex;
  max-width: 530px;
  flex-direction: column;
  font-family: Manrope, sans-serif;
  padding: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.evaluation-header {
  width: 100%;
}

.heading {
  color: #000;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.free-evaluation {
  border-radius: 6px;
  background-color: #f5f5f5;
  padding: 6px;
  margin-bottom: 30px;
}

.evaluation-count {
  border-radius: 4px;
  background-color: #fff;
  font-size: 17px;
  color: #000;
  font-weight: 500;
  padding: 8px 0;
  text-align: center;
  /* margin-bottom: 0px !important; */
}

.question-section {
  margin-bottom: 30px;
  width: 100%;
}

.question-label {
  display: block;
  font-size: 15px;
  color: #000;
  font-weight: 500;
  margin-bottom: 16px;
}

.input-container {
  width: 100%;
}

.question-input {
  width: 100%;
  min-height: 40px;
  font-size: 15px;
  color: #1c1919;
  border: none;
  resize: vertical;
  display: flex;
  padding: 11px 16px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  background: #f5f5f5;
}

.character-count {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  font-size: 11px;
  color: #2e2e2e;
  margin-top: 8px;
}

.count-icon {
  width: 13px;
  height: 13px;
  object-fit: contain;
}

.image-upload-section {
  display: flex;
  flex-direction: column !important;
  margin-bottom: 30px;
  width: 381px;
  padding-top: 39px;
  padding-bottom: 39px;
  padding-right: 34.5px;
  padding-left: 34.5px;
  background-color: #f6fff6;
}

.upload-title {
  font-size: 15px;
  color: #000;
  font-weight: 500;
  margin-bottom: 16px;
}

.upload-area {
  border-radius: 6px;
  background-color: #f6fff6;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 39px 35px;
  border: 1px dashed rgba(31, 91, 67, 0.2);
}

.upload-icon {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  margin-bottom: 20px;
}

.upload-instruction {
  font-size: 16px;
  color: #1f1f3f;
  text-align: center;
  line-height: 1;
}

.choose-file {
  color: #2f8965;
  cursor: pointer;
}

.upload-limit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  font-size: 11px;
  color: #2e2e2e;
  margin-top: 8px;
}

.limit-icon {
  width: 13px;
  height: 13px;
  object-fit: contain;
}

.evaluate-button {
  align-self: center;
  border-radius: 6px;
  background-color: #23b279;
  width: 100%;
  min-height: 40px;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  line-height: 1;
  padding: 9px 10px;
  border: none;
  cursor: pointer;
}

@media (max-width: 991px) {
  .evaluation-container {
    padding: 20px;
  }

  .heading,
  .free-evaluation,
  .evaluation-count,
  .question-section,
  .input-container,
  .question-input,
  .image-upload-section,
  .upload-area {
    max-width: 100%;
  }

  .upload-area {
    padding: 39px 20px;
  }

  .evaluate-button {
    white-space: normal;
  }
}

.pdf-viewer {
  width: 331px;
  height: 80px;
  display: flex;
  max-width: 554px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: start;
  font: 400 16px Manrope, sans-serif;
}

.button-container-answer-e {
  border-radius: 16px;
  background-color: rgba(241, 242, 241, 0.4);
  width: 311px !important;
  height: 80px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: start;
  margin-top: 22px;
  padding: 10px;
}

.button-container-back {
  border-radius: 16px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 145px;
  margin-top: 22px;
  padding: 10px;
  background-color: rgba(241, 242, 241, 0.4);
}

.button-answer-e {
  border-radius: 6px;
  display: flex;
  min-height: 40px;
  align-items: center;
  gap: 6px;
  justify-content: center;
  margin: auto 0;
  padding: 10px;
  border: none;
  width: 130px;
  height: 20px;

  cursor: pointer;
}

.button.primary {
  background-color: rgba(228, 255, 224, 1);
  color: rgba(31, 91, 67, 1);
  border: 1px solid rgba(31, 91, 67, 0.15);
}

.button.secondary {
  background-color: transparent;
  color: rgba(47, 137, 101, 1);
}

.button-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  align-self: stretch;
  margin: auto 0;
}

.button-text-answer-e {
  align-self: stretch;
  margin: auto 0;
  font-size: 14px;
  color: #2f8965;
  text-align: center;
  font-family: Manrope;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
}

.selected {
  background-color: #e4ffe0;
}
.highlightpdf{
  position: relative;
  background-color: white;
  width: 100%;
  height: 100%;
}
@property --angle {
  syntax: '<angle>';  
  initial-value: 0deg;
  inherits: false;
}

.highlightpdf::after,
.highlightpdf::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0.6rem 1.1rem;
  width: calc(100% + 7px);
  height: calc(100% + 3px);
  background-color: var(--primary-color);
  background-image: conic-gradient(
    from var(--angle),
    #23b279 0deg,
    #ffffff 90deg,
    #ffffff 180deg,
    #23b279 360deg
  );
  border-radius: .3rem;
  z-index: -1;
  animation: spin 4s linear infinite; /* Increased to 4s to include 1s pause */
}

@keyframes spin {
0% {
  --angle: 360deg;
  opacity: 1;
}
/* 75% { 
  --angle: 0deg;
  opacity: 1;
}
75.1% { 
  opacity: 0;
}
99.9% { 
  opacity: 0;
} */
100% {
  --angle: 0deg;
  opacity: 1;
}
}
.highlightpdf::before {
  filter: blur(0.2rem);
  opacity: 0.5;
}


.evaluation-form {
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 9px 12px rgba(0, 0, 0, 0.04);
  display: flex;
  max-width: 560px;
  flex-direction: column;
  font-family: Manrope, sans-serif;
  padding: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.evaluation-form__heading {
  font-size: 17px;
  color: #000;
  font-weight: 500;
  margin-bottom: 40px;
}
.subject-selector {
  margin-bottom: 40px;
}
.subject-selector__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.subject-selector__title {
  font-size: 17px;
  font-weight: 500;
}
.subject-selector__sample-btn {
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 16px;
  padding: 7px 12px;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  background-color: #fff;
  cursor: pointer;
}
.subject-selector__sample-icon {
  width: 20px;
  height: 20px;
}
.subject-selector__options {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
.subject-selector__option {
  border-radius: 100px;
  background-color: #fff;
  color: #000;
  font-size: 18px;
  font-weight: 400;
  padding: 10px 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.subject-selector__option--active {
  background-color: #f6fff6;
  color: #2f8965;
  font-weight: 500;
}
.upload-section {
  margin-bottom: 40px;
}
.upload-section__title {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 16px;
}
.upload-section__dropzone {
  border-radius: 6px;
  background-color: #f6fff6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 63px 50px;
  border: 1px dashed rgba(31, 91, 67, 0.2);
  text-align: center;
  cursor: pointer;
}
.upload-section__icon {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  margin-bottom: 20px;
}
.upload-section__instruction {
  font-size: 19px;
  color: #1f1f3f;
  line-height: 1;
}
.upload-section__choose-file {
  color: #2f8965;
}

@media (max-width: 991px) {
  .evaluation-form {
    padding: 20px;
  }
  .upload-section__dropzone {
    padding: 40px 20px;
  }
}

.Uploading-answer-container {
  color: #2f8965;
  text-align: center;
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 450px;
  height: 561px;
}

.evaluation-container {
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.04);
  display: flex;
  max-width: 560px;
  flex-direction: column;
  font-family: Manrope, sans-serif;
  justify-content: start;
  padding: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.heading {
  font-size: 17px;
  color: #000000;
  font-weight: 500;
  margin-bottom: 40px;
}
.subjects {
  display: flex;
  width: 100%;
  flex-direction: column;
  color: #000000;
  justify-content: start;
}
.container {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 40px 100px;
  font-weight: 500;
  justify-content: space-between;
  margin-bottom: 20px;
}
.select-subject {
  color: #000;
  font-family: Manrope;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.example {
  border-radius: 6px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 16px;
  text-align: center;
  justify-content: center;
  padding: 7px 12px;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}
.img {
  width: 20px;
  height: 20px;
}
.sample-evaluation {
  color: #000000;
}
.subjects-2 {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 24px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  justify-content: start;
}
.subjects-3 {
  display: flex;
  min-width: 240px;
  align-items: center;
  gap: 24px;
  justify-content: start;
  flex-wrap: wrap;
}
.subject-button {
  border-radius: 100px;
  background-color: #ffffff;
  color: #000000;
  font-weight: 400;
  padding: 10px 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.subject-button.active {
  background-color: #f6fff6;
  color: #2f8965;
  font-weight: 500;
}
.image-upload {
  display: flex;
  margin-top: 40px;
  width: 100%;
  flex-direction: column;
  font-weight: 500;
  justify-content: start;
}
.answer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
}
.upload-your-hand-written-answer {
  color: #000000;
  font-size: 17px;
  margin-bottom: 16px;
}
.answer-2 {
  border-radius: 6px;
  background-color: #f6fff6;
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  font-size: 19px;
  color: #1f1f3f;
  text-align: center;
  line-height: 1;
  justify-content: center;
  padding: 63px 50px;
  border: 1px dashed rgba(31, 91, 67, 0.2);
}
.description {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}
.img-2 {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  margin-bottom: 20px;
}
.drag-drop-your-answer-pdf-here-or-choose-file {
  margin-top: 20px;
}
.choose-file {
  color: #2f8965;
  cursor: pointer;
}
.button-evaluate {
  align-self: center;
  border-radius: 6px;
  background-color: #2cde97;
  margin-top: 40px;
  width: 100%;
  font-size: 19px;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  padding: 12px;
  border: none;
  cursor: pointer;
}
@media (max-width: 991px) {
  .evaluation-container {
    padding: 20px;
  }
  .answer-2 {
    padding: 40px 20px;
  }
}

.upload-answer-container {
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  box-shadow: 0px 9px 12px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  width: 450px;
  height: 561px;
  padding: 20px;
}

.file-upload-icon-g {
  width: 60px;
  height: 59px;
  border-radius: 50%;
  background-color: #e4ffe0;
}

.pdf-preview {
  width: 381px;
  display: flex;
  flex-direction: column;
  /* overflow: auto; */
  gap: 4px;
  /* height: 200px; */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.pdf-preview::-webkit-scrollbar {
  width: 8px;
}

.pdf-preview::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.pdf-preview::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.pdf-preview::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.text-slate-600 {
  color: #1f1f3f;
  text-align: center;
  font-family: Manrope;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.w-100 flex justify-center {
  background-color: #e4ffe0;
  width: 60px;
  height: 59px;
  border: 1px;
  border-radius: 30px;
}

.uploaded-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f3f3f3;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.uploaded-file span {
  font-size: 17px;
  color: #333;
}

.delete-icon {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.unlock-feature {
  border-radius: 6px;
  background-color: #f6fff6;
  display: flex;
  max-width: 480px;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  padding: 63px 50px;
  border: 1px dashed rgba(31, 91, 67, 0.2);
}
.unlock-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.lock-icon {
  display: flex;
  min-height: 59px;
  width: 60px;
  align-items: center;
  justify-content: center;
}
.lock-image {
  aspect-ratio: 1.02;
  object-fit: contain;
  object-position: center;
  width: 60px;
  border-radius: 30px;
  background-color: #ffefec;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.unlock-text {
  color: #ff1304;
  text-align: center;
  margin-top: 20px;
  font: 500 16px/1 Manrope, sans-serif;
}

.back-button {
  align-self: stretch;
  border-radius: 6px;
  background-color: #e4ffe0;
  color: #1f5b43;
  text-align: center;
  width: 125px;
  padding: 9px 10px;
  font: 400 16px Manrope, sans-serif;
  border: 1px solid rgba(31, 91, 67, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #d0ffc9;
}

.back-button:focus {
  outline: 2px solid #1f5b43;
  outline-offset: 2px;
}

.try-zenith-button {
  align-self: stretch;
  border-radius: 6px;
  background-color: rgba(44, 222, 151, 1);
  color: #fff;
  text-align: center;
  padding: 9px 10px;
  font: 400 16px/1 Roboto, sans-serif;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 480px;
}

.try-zenith-button:hover {
  background-color: rgba(39, 199, 135, 1);
}

.try-zenith-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(44, 222, 151, 0.5);
}

.try-zenith-container {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.answer-e-file-container {
  border-radius: 6px;
  background-color: #fff;
  display: flex;
  max-width: 381px;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  font: 400 12px Roboto, sans-serif;
  border: 1px solid rgba(31, 91, 67, 0.1);
  color: var(--Text, #374151);
}
.answer-e-icon-with-text {
  display: flex;
  align-items: center;
  gap: 23px;
}
.answer-e-icon {
  aspect-ratio: 0.78;
  object-fit: contain;
  width: 14px;
}
.answer-e-text {
  align-self: stretch;
  font-size: 18px;
}
.answer-e-action-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}
.answer-e-action-icon {
  aspect-ratio: 1;
  object-fit: contain;
  width: 10px;
  stroke-width: 1.5px;
  stroke: var(--Text, #374151);
}

.file-icon-plus-container {
  background-color: #e4ffe0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
}

.image-card {
  border-radius: 6px;
  background-color: #fff;
  display: flex;
  min-height: 135px;
  align-items: center;
  justify-content: center;
  width: 125px;
  padding: 10px 7px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.image-content {
  display: flex;
  width: 100px;
  flex-direction: column;
  justify-content: start;
}
.image-header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.image-number {
  color: #000;
  text-align: center;
  width: 6px;
  font: 400 11px Manrope, sans-serif;
}
.image-actions {
  display: flex;
  align-items: center;
  gap: 7px;
}
.action-button {
  border-radius: 96px;
  background-color: #ebebf4;
  display: flex;
  min-height: 20px;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
}
.action-icon {
  width: 10px;
  height: 10px;
}
.uploaded-image {
  aspect-ratio: 1.25;
  object-fit: contain;
  width: 100px;
  margin-top: 4px;
  max-width: 100%;
}
.image-caption {
  color: #374151;
  margin-top: 4px;
  font: 400 11px/1 Manrope, sans-serif;
}
.add-image-button {
  color: #2f8965;
  margin-top: 10px;
  font: 400 12px Manrope, sans-serif;
  background: none;
  border: none;
  cursor: pointer;
}
.upload-instructions {
  color: #374151;
  margin-top: 10px;
  font: 300 11px Roboto, sans-serif;
}
.emphasis {
  font-weight: 400;
}

.answer-e-loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.answer-e-error-alert {
  border-radius: 4px;
  background-color: rgba(255, 239, 236, 1);
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(255, 19, 4, 1);
  text-align: center;
  justify-content: start;
  padding: 6px 12px;
  font: 400 12px Manrope, sans-serif;
  border: 1px solid rgba(255, 20, 3, 0.2);
}
.answer-e-alert-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 14px;
  margin: auto 0;
}
.answer-e-alert-message {
  margin: auto 0;
}
.answer-e-visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media screen and (max-width: 768px) {
  .answer-evaluation-container-sub{
    /* margin-top: 100px; */
  }
  .answer-evaluation-content-m{
    /* display: flex; */
    /* background-size: cover; */
    /* height: 100vh; */
    width: 120%;
  }
  .evaluation-container{
    width: 100%;
  }
}