.interview-container {
    background-color: #fff;
    display: flex;
    padding-top: 90px;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    text-align: center;
    justify-content: start;
    /* height: 100vh; */
    margin-bottom: 50px;
  }
  
  .content-wrapper {
    background-color: #fff;
    display: flex;
    width: 1157px;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: start;
  }
  
  .header-section {
    display: flex;
    width: 521px;
    max-width: 100%;
    flex-direction: column;
    justify-content: start;
  }
  
  .title-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: start;
  }
  
  @media (max-width: 991px) {
    .title-wrapper {
      max-width: 100%;
    }
  }
  
  .main-title {
    color: #1f1f1f;
    font-size: 30px;
    font-weight: 600;
    line-height: 2;
  }
  
  @media (max-width: 991px) {
    .main-title {
      max-width: 100%;
    }
  }
  
  .subtitle {
    color: #000;
    font-size: 22px;
    font-weight: 400;
    margin-top: 8px;
  }
  
  @media (max-width: 991px) {
    .subtitle {
      max-width: 100%;
    }
  }
  
  .category-badge {
    align-self: center;
    border-radius: 6px;
    background-color: #e4ffe0;
    margin-top: 16px;
    min-height: 30px;
    width: 135px;
    max-width: 100%;
    font-size: 18px;
    color: #23b279;
    font-weight: 500;
    line-height: 1;
    padding: 4px 10px;
  }
  
  .description-section {
    display: flex;
    margin-top: 30px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    color: var(--Text, #374151);
    font-weight: 400;
    justify-content: start;
  }
  
  .primary-text {
    line-height: 30px;
  }
  
  @media (max-width: 991px) {
    .primary-text {
      max-width: 100%;
    }
  }
  
  .secondary-text {
    margin-top: 8px;
  }
  
  @media (max-width: 991px) {
    .secondary-text {
      max-width: 100%;
    }
  }
  
  .ai-mock-start-button {
    align-self: center;
    border-radius: 6px;
    background-color: #23b279;
    margin-top: 30px;
    min-height: 48px;
    width: 301px;
    max-width: 100%;
    font-size: 20px;
    color: #fff;
    font-weight: 500;
    line-height: 1.1;
    padding: 13px 10px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .start-button:hover {
    background-color: #1e9c69;
  }
  
  .start-button:focus {
    outline: 2px solid #23b279;
    outline-offset: 2px;
  }

  .upload-container-ai-mock {
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    padding-top: 30px;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    font-family: Manrope, sans-serif;
    justify-content: start;
    height:100%;
    /* margin-bottom: 70px; */
  }
  .header-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 28px;
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    justify-content: start;
  }
  .header-icon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 50px;
    fill: #e4ffe0;
    stroke-width: 1px;
    stroke: #d1f8d6;
    filter: drop-shadow(0px 9px 12px rgba(0, 0, 0, 0.04));
  }
  .header-title {
    margin-top: 16px;
  }
  .upload-field {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    margin-top: 40px;
    width: 531px;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 56px;
    border: 1px dashed rgba(31, 91, 67, 0.5);
  }
  @media (max-width: 991px) {
    .upload-field {
      padding: 0 20px;
    }
  }
  .upload-icon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 48px;
  }
  .upload-content {
    display: flex;
    margin-top: 24px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .upload-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 400;
    justify-content: center;
  }
  .upload-text {
    color: var(--Text, #374151);
    font-size: 20px;
  }
  .file-types {
    color: rgba(55, 65, 81, 0.6);
    font-size: 16px;
    margin-top: 12px;
  }
  .select-button-ai-mock {
    align-self: center;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 1);
    margin-top: 24px;
    min-height: 40px;
    width: 180px;
    max-width: 100%;
    gap: 10px;
    font-size: 20px;
    color: rgba(35, 178, 121, 1);
    font-weight: 500;
    text-align: center;
    line-height: 1.1;
    padding: 9px 10px;
    border: 1px solid rgba(35, 178, 121, 1);
    cursor: pointer;
  }
  .interview-text {
    color: var(--Text, #374151);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    margin-top: 40px;
  }
  @media (max-width: 991px) {
    .interview-text {
      max-width: 100%;
    }
  }
  .details-button {
    align-self: center;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 1);
    margin-top: 40px;
    min-height: 28px;
    width: 457px;
    max-width: 100%;
    gap: 10px;
    font-size: 16px;
    color: rgba(45, 128, 246, 1);
    font-weight: 400;
    text-align: center;
    padding: 3px 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  .daf-overview {
    padding-bottom: 30px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 9px 12px 0px rgba(0, 0, 0, 0.04);
    background-color: #fff;
    display: flex;
    max-width: 870px;
    flex-direction: column;
    overflow: hidden;
    font-family: Manrope, sans-serif;
    justify-content: start;
    height: auto;
  }
  .section-header {
    align-self: stretch;
    border-radius: 12px 12px 0px 0px;
    background-color: rgba(254, 254, 254, 1);
    box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.04);
    border-color: rgba(0, 0, 0, 0.1);
    border-bottom-width: 1px;
    min-height: 50px;
    width: 100%;
    font-size: 22px;
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    text-align: center;
    padding: 10px 0;
  }
  .fields-container {
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    width: 100%;
    align-items: center;
    gap: 30px;
    font-size: 14px;
    line-height: 1;
    justify-content: start;
    flex-wrap: wrap;
    padding: 40px 30px 30px;
  }
  .field-wrapper {
    align-self: stretch;
    display: flex;
    min-width: 240px;
    flex-direction: column;
    justify-content: start;
    flex-grow: 1;
    width: 200px;
    margin: auto 0;
  }
  .field-label-ai-mock {
    color: var(--Text, #374151);
    font-weight: 500;
    font-size: 18px;
  }
  .field-input {
    border-radius: 6px;
    display: flex;
    margin-top: 8px;
    min-height: 40px;
    width: 100%;
    align-items: center;
    gap: 12px;
    color: var(--Neutral-900, #111827);
    font-weight: 400;
    justify-content: start;
    padding: 12px 8px;
    border: 1px solid rgba(229, 231, 235, 1);
  }
  .field-content {
    align-self: stretch;
    display: flex;
    width: 100%;
    align-items: center;
    gap: 8px;
    justify-content: start;
    flex: 1;
    flex-basis: 0%;
    margin: auto 0;
  }
  .field-text {
    align-self: stretch;
    gap: 2px;
    margin: auto 0;
  }
  .full-width {
    width: 760px;
  }
  @media (max-width: 991px) {
    .section-header,
    .fields-container,
    .field-wrapper,
    .field-input,
    .field-content {
      max-width: 100%;
      white-space: initial;
    }
    .fields-container {
      padding: 0 20px;
    }
  }

  .uploaded-file-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    margin-top: 20px;
    border: 2px solid #1f5b43;
    border-radius: 12px;
    background-color: #f4f9f4;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }
  
  .uploaded-file-container:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
  
  .file-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 12px;
  }
  
  .file-details span {
    font-size: 16px;
    color: #1f5b43;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .file-details span:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  
  .delete-icon {
    cursor: pointer;
    color: #d9534f; /* Red for delete */
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  
  .delete-icon:hover {
    transform: scale(1.2);
    opacity: 0.9;
  }
  
  .delete-icon:active {
    transform: scale(0.95);
  }
  
  .uploaded-file-container:focus-within {
    outline: 2px solid #85bda6;
  }
  
  .input-field{
    border: none;
  }

  .daf-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 90px;
    /* height: 100vh; */
  }

  .submit-button-ai-mock{
    display: flex;
    width: 250px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #23B279;
    color: whitesmoke;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .input-field-ai-mock {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: none;  
    outline: none; 
    /* background-color: #f7f7f7; */
    border-radius: 4px;
    box-shadow: none;  
    border: 1px solid #e5e7eb;
  }
  
  .input-field-ai-mock:focus { 
    background-color: #fff;
    box-shadow: 0 0 5px rgba(31, 91, 67, 0.5);
   }
  

    :root {
      --primary: #23b279;
      --text: #374151;
      --link: #2D80F6;
      --border: rgba(0, 0, 0, 0.1);
      --shadow: rgba(0, 0, 0, 0.05);
      --background: #ffffff;
    }

    .success-wrapper {
      background-color: var(--background);
      display: flex;
      padding: 120px 16px 40px;
      flex-direction: column;
      overflow: hidden;
      align-items: center;
      min-height: 100vh;
      font-family: Manrope, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    }

    .success-card {
      border-radius: 20px;
      background-color: var(--background);
      box-shadow: 0 9px 12px var(--shadow);
      display: flex;
      width: 574px;
      max-width: 100%;
      flex-direction: column;
      align-items: center;
      padding: 30px 59px 62px;
      border: 1px solid var(--border);
    }

    .success-icon {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 90px;
      height: 90px;
    }

    .success-title {
      color: var(--text);
      font-size: 26px;
      font-weight: 600;
      margin: 30px 0 40px;
      text-align: center;
    }

    .button-container {
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 100%;
    }

    .button {
      align-self: stretch;
      border-radius: 6px;
      min-height: 30px;
      text-align: center;
      padding: 10px;
      width: 100%;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      font-family: inherit;
    }

    .button:focus {
      outline: 2px solid var(--primary);
      outline-offset: 2px;
    }

    .primary {
      border: 1px solid var(--primary);
      color: var(--primary);
      font-size: 20px;
      line-height: 1.1;
      background-color: transparent;
    }

    .primary:hover {
      background-color: var(--primary);
      color: var(--background);
    }

    .secondary {
      background-color: var(--background);
      border: 1px solid var(--border);
      color: var(--link);
      font-size: 16px;
      padding: 4px 10px;
    }

    .secondary:hover {
      background-color: var(--link);
      color: var(--background);
      border-color: var(--link);
    }

    @media (max-width: 991px) {
      .success-wrapper {
        padding-top: 100px;
      }
      .success-card {
        padding: 24px 20px;
      }
      .success-title {
        font-size: 22px;
        margin: 24px 0 32px;
      }
    }

    @media (prefers-reduced-motion: reduce) {
      .button {
        transition: none;
      }
    }

    .visually-hidden {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }

    .topics-container {
      margin: 20px 0;
      padding: 15px;
      border: 1px solid #ccc;
      border-radius: 8px;
    }
    
    .filter-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 12px;
      color: #333;
      text-transform: uppercase;
      letter-spacing: 1px;
      border-bottom: 2px solid #007bff;
      padding-bottom: 5px;
    }
    
    .topics-list {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
    }
    
    .topic-label {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px 15px;
      border: 2px solid #ddd;
      border-radius: 8px;
      color: #444;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    
    .topic-label:hover {
      color: #007bff;
      border-color: #007bff;
      box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
      transform: translateY(-2px);
    }
    
    input[type="checkbox"] {
      accent-color: #007bff;
      transform: scale(1.3);
      cursor: pointer;
    }
    
    input[type="checkbox"]:checked + label {
      font-weight: bold;
      color: #0056b3;
      border-color: #0056b3;
      box-shadow: 0 4px 8px rgba(0, 86, 179, 0.3);
      transform: translateY(-2px);
    }
    