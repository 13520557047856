* {
  /* box-sizing: border-box; */
  /* outline: 3px solid limegreen !important; */
}
html * {
  /* font-family: "Nunito Sans", sans-serif !important; */
  /* font-family: "Chakra Petch", sans-serif !important; */
  font-family: Manrope, -apple-system, Roboto, Helvetica, sans-serif !important;
}

/* body {
  font-family: "Asap", sans-serif;
} */

abbr {
  /* display: none; */
}

.react-calendar__tile abbr {
  display: none;
}

.cardLinks {
  all: unset;
  cursor: pointer;
}
