.table {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 1500px;
  overflow-y: auto;
}

.table .tableRow {
  display: flex;
  width:100%;
}

  .table .tableRowValues{
    display: flex;
    width:100%;
    /* justify-content: space-around; */
  }
  
  .tableCell {
    flex: 1;
    text-align: center;
    white-space: nowrap; 
    text-overflow: ellipsis;
    flex-basis: 10%; 
  }
  
  .table .status {
    padding: 5px;
    border-radius: 5px;
    text-align: center;
    display: inline-block;
    width: 100px; 
  }
  
  .table .status.InQueue {
    color: goldenrod;
    background-color: rgba(189, 189, 3, 0.103);
  }
  
  .table .status.Processed {
    color: green;
    background-color: rgba(0, 128, 0, 0.151);
  }
  
  .table .actions {
    display: flex;
  }
  
  .table .action-icon {
    font-size: 20px;
    margin-right: 10px;
    cursor: pointer;
    color: #007bff;
  }
  
  .table .action-icon:hover {
    color: #0056b3;
  }
  
  .pdfNameCell {
    max-width: 300px; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
    cursor: pointer; 
  }
  
  .pdfNameCell:hover::before {
    content: attr(title); 
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px;
    border-radius: 3px;
    z-index: 1;
    white-space: nowrap;
  }
  .tableRowPagination {
    display: flex;
    justify-content: center;

  }
  .empty-list-message{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .empty-list-image{
    height: 300px;
    width: 400px;
  }

  .empty-list-text{
    color: rgb(247, 81, 81);
  }

  .change-status-button {
    background-color: #1976d2;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase;
    transition: background-color 0.3s ease-in-out;
  }
  
  .change-status-button:hover {
    background-color: #1565c0;
  }
  
  .change-status-button:disabled {
    background-color: #9e9e9e;
    cursor: not-allowed;
  }
  
  .actions-icons-r{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .previous-next-button {
    background-color: #4CAF50; 
    border: none; 
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px; 
    margin: 4px 2px; 
    cursor: pointer; 
    border-radius: 8px; 
    transition: background-color 0.3s ease;
  }
  
  .previous-next-button:hover {
    background-color: #45a049; 
  }
  
  .previous-next-button:disabled {
    background-color: #ccc; 
    color: #666; 
    cursor: not-allowed;
  }
  
  .page-button {
    background-color: #f0f0f0;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px 12px;
    margin: 0 5px; 
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .page-button:hover {
    background-color: #e0e0e0;
  }
  
  .page-button.active {
    background-color: #007bff;
    color: white;
    border: 1px solid #007bff;
  }
  
  .page-button:disabled {
    background-color: #dcdcdc; 
    color: #a9a9a9; 
    cursor: not-allowed;
  }
  
  .page-button:not(:disabled) {
    opacity: 1;
  }

  .pagination-container-cb{
    display: flex;
    align-items: center;
    margin: auto;
    width: 100%;
    justify-content: center;
    gap: 10px;
  }