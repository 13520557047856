.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* width: 200px; */
  padding: 10px;
  border-top: none;
  background-color: #fff;
  box-shadow: 2px 8px 5px rgba(0, 0, 0, 0.1); 
  /* height: 90%; */
  gap: 10px;
  position: fixed;
  bottom: 0px;
  z-index: 1000;
}
.sidebarOpen {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1000;
  padding: 10px;
  background-color: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  /* height: 90%; */
  gap: 10px;
  position: fixed;
  bottom: 0px;
}

.menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  display: flex;
  width: 72px;
  height: 70px;
  padding: 10px 16px;
  justify-content: center;
  gap: 10px;
}

.row-sideBarItems {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  display: flex;
  width: 160px;
  height: 60px;
  padding: 10px 16px;
  /* justify-content: space-between; */
  gap: 16px;
}

.text {
  text-align: center;
  color: #000000;
  text-align: center;
  font-family: Manrope;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.isSidebarOpen-text {
  text-align: left;
  color: #000000;
  text-align: left;
  font-family: Manrope;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.active-text {
  text-align: center;
  color: #f0f0f0;
  text-align: center;
  font-family: Manrope;
  /* font-size: 10px; */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.active-item {
  background-color: #2f8965;
  color: #fff;
  border-radius: 5px;
}

/* 
@media screen and (max-width: 768px) {
  .sidebar {
    display: none;
  }
  .sidebarOpen {
    display: none;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .sidebar {
    display: block;
  }
  .sidebarOpen {
    display: block;
  }
} */

