.container-temp {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-temp {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  justify-content: space-between;
}

.header-temp label {
  margin-right: 10px;
}

.input-number-temp,
.input-file-temp,
.subject-input-temp,
.assignment-name-input-temp {
  margin-right: 20px;
  padding: 5px;
  font-size: 16px;
}

.content-temp {
  display: flex;
  width: 100%;
  gap: 20px;
}

.table-container-temp {
  overflow-x: auto;
  height: 800px;
  flex: 1;
}

table.table-temp {
  width: 100%;
  border-collapse: collapse;
  background-color: #f9f9f9;
}

th.th-temp,
td.td-temp {
  border: 1px solid #ddd;
  padding: 6px;
  text-align: center;
}

th.th-temp {
  background-color: #4CAF50;
  color: white;
}

td.td-temp input[type="number"] {
  width: 50px;
  padding: 5px;
  font-size: 14px;
  text-align: center;
}

.td.td-temp textarea {
  width: 800px;
  min-height: 60px;
  padding: 5px;
  font-size: 14px;
  resize:both;
  border:2px solid #333;
}

.textarea-temp{
  width: 100%;
  min-height: 60px;
  padding: 5px;
  font-size: 14px;
  resize:vertical;
  border:1px solid #333;
}
options-list-container-b2c{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* adjust the column width to your needs */
  gap: 10px;
}

.increment-decrement-btn {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  background-color: #4CAF50;
  color: white;
  text-align: center;
  cursor: pointer;
  margin: 0 2px;
}

.pdf-preview-temp {
  width: 45%;
  height: 800px;
  margin-left: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  box-sizing: border-box;
  overflow: auto;
}

.customViewer-temp {
  width: 100%;
  height: 100%;
}

.customCanvas-temp {
  width: 100% !important;
  height: auto !important;
}

.download-btn-temp,
.select-btn-temp,
.unselect-btn-temp,
.search-btn-temp {
  padding: 8px 12px;
  margin: 0 5px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.download-btn-temp:hover,
.select-btn-temp:hover,
.unselect-btn-temp:hover,
.search-btn-temp:hover {
  background-color: #0056b3;
}

.client-search-container {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 90%;
  margin: 0 auto;
}

.client-select-wrapper {
  margin-bottom: 15px;
  text-align: center;
}

.client-label {
  font-size: 16px;
  margin-right: 10px;
}

.client-select {
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-input {
  flex: 1;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 30px 0 0 30px;
  border-right: none;
}

.search-btn {
  padding: 10px 15px;
  font-size: 16px;
  color: #fff;
  background-color: #4285f4;
  border: 1px solid #4285f4;
  border-radius: 0 30px 30px 0;
  cursor: pointer;
}

.search-btn:hover {
  background-color: #357ae8;
}

.search-btn i {
  font-size: 16px;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #4285f4;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.search-results-container {
  margin-top: 20px;
  text-align: center;
}

.search-results-title {
  font-size: 18px;
  font-weight: bold;
}

.search-results-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.search-result-item {
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}

.label-temp {
  font-weight: bold;
  margin-right: 10px;
}

.input-number-temp {
  width: 100px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.label-temp-tag {
  color: #333;  
  font-size: 14px;  
}

.input-number-temp-tag {
  color: #555;  
  background-color: #f9f9f9;  
}

.upload-container-temp {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.upload-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.upload-label {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.input-file {
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 6px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.input-file:hover {
  background-color: #f5f5f5;
  border-color: #aaa;
}

.input-file:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.4);
}

.upload-button {
  padding: 12px 20px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-button:hover {
  background-color: #0056b3;
}


.form-container-temp {
  margin: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.input-group-temp {
  margin-bottom: 10px;
}

.label-temp {
  font-weight: bold;
  margin-right: 10px;
}

.subject-input-temp, .assignment-name-input-temp {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 200px; 
}

.download-save-btn-temp{
  display: flex;
  gap: 20px;
  margin-top: 5px;
  flex-direction: column;
}

.required-asterisk-temp {
  color: red;
  margin-left: 2px;
}

.answer-sheet-container{
  display:flex;
}

.upload-sections-question{
  display: flex;
  flex-direction: column;
}