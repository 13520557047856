.banner-container {
    height: 6.5rem; 
    background-color:#EAE5F8;
    padding-bottom: 4rem; 
  }
  
  .gradient-left,
  .gradient-right {
    position: absolute;
    top: 50%;
    -z-index: 10;
    transform: translateY(-50%) translateZ(0);
    blur-radius: 2xl;
  }
  
  .gradient-left {
    left: max(-7rem, calc(50% - 52rem));
    width: 36.0625rem;
    background: linear-gradient(to right, #ff80b5, #9089fc);
    opacity: 0.3;
    clip-path: polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%);
  }
  
  .gradient-right {
    left: max(45rem, calc(50% + 8rem));
    width: 36.0625rem;
    background: linear-gradient(to right, #ff80b5, #9089fc);
    opacity: 0.3;
    clip-path: polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%);
  }
  
  .promotion-text {
    font-size: 0.9rem;
    line-height: 1rem;
  }
  
  @media screen and (min-width: 768px) {
    .banner-container{
        height: 3.5rem;
    }
  }